@import '/styles/variables.module.scss';
// $secondary-color: Variables.secondaryColor; // assuming Variables.secondaryColor is a valid SCSS variable
.highlightWord {
  color: $darkorange-color;
}
.capitals {
  text-transform: uppercase;
  // color: #171717;
}

.developerCardGrid {
  height: 100%;
  max-height: 450px;
}

.livabilityData {
  // margin-top: -20px !important;
}

.livabilityHeading {
  h1 {
    letter-spacing: -0.01562em !important;
    font-family: Open Sans, sans-serif !important;
    font-weight: bold;
    line-height: 1;
    font-size: 24px;
    color: #0056b2;
    padding-right: 18px;
    // border-right: 3px solid #17171721;
    padding-bottom: 3px;
    @media (min-width: 769px) and (max-width: 1025px) {
      font-size: 17px;
    }
    @media (min-width: 1025px) and (max-width: 1267px) {
      font-size: 22px;
    }
  }
  p {
    font-family: Open Sans, sans-serif !important;
    font-weight: 400;
    letter-spacing: -0.01562em !important;
    text-transform: none;
    line-height: 1 !important;
    font-size: 16px;
    color: #171717;
    padding-top: 5px;
    @media (min-width: 769px) and (max-width: 899px) {
      font-size: 13px;
    }
    @media (min-width: 900px) and (max-width: 1025px) {
      font-size: 14px;
    }
  }
}
.livabilityData {
  h4 {
    cursor: pointer;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 95%;
    font-weight: 700;
  }

  h5 {
    margin-top: 5px;
    color: #6f6f6f;
    font-size: 14px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
  }
}

.priceContent {
  color: var(--dark-color);
  font-size: 18px;
  font-weight: 700;
  font-family: 'Open Sans';
}

// tooltip

.more-detail-tooltip {
  color: #000;
  background-color: #fff;
  border: 1px solid #D3E0ED;
  width: 150px;
  font-size: 12px;
  padding: 10px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}

.more-detail-tooltip .MuiTooltip-arrow {
  color: #fff;

  &::before {
    border: 1px solid #D3E0ED;
  }
}

.noBorderRight h1{
  border-right: 3px solid #17171721;
}