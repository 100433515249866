.wrapper {
  box-sizing: border-box;
  display: flex;
  scroll-snap-type: both mandatory;
  overflow-x: scroll;
}

.wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.wrapper .previco-our-culture {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
}

.wrapper .nextico-our-culture {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}

.item {
  scroll-snap-align: start;
  padding-bottom: 0.5rem;
}

.positionRelative {
  position: relative;
}

@media (min-width: 1023px) and (max-width: 1024px) {
  .wrapper {
    overflow-x: scroll;
    padding: 0 15px 0px 0px; /* Optional: to add spacing around the wrapper */
  }

  .item {
    // width: 379px;
   margin-right: 13px;
  }
}
