@import '/styles/variables.module.scss';
.root {
  border: 0;
  padding-bottom: 0px;
  margin-right: 12px;
  box-shadow: none !important;
  background: transparent !important;
  border-radius: 6px;
}

.root .MuiCardContent-root {
  padding: 0;
}

.root .MuiCardContent-root img {
  border-radius: 6px;
  /* overflow: hidden; */
  /* margin-right: 3px; */
}

.cardTop {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.cardTop button {
  color: #fff !important;
}

.cardTop button img {
  width: 22px !important;
  height: 22px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border-radius: 50% !important;
  padding: 4px;
}

.cardTop img {
  border-radius: 6px;
  margin-top: 5px;
  margin-right: 0px;
  overflow: hidden;
}

.cardTopIcons {
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 16px 18px;
  align-items: center;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0%), rgba(17, 31, 35, 85%));
  width: 100%;
}

.cardTopDetails {
  padding-right: 10px;
}

.cardBody {
  padding: 10px 15px;
  /* border: 1px solid #D2DEEB; */
  /* border-radius: 7px; */
  padding-bottom: 0px;
}

.cardDetails {
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-wrap: wrap;
  margin-top: -4px;
}

.media {
  height: 50px;
  padding-top: 56.25%; /* 16:9 */
}

.location {
  display: flex;
  align-items: center;
  margin-top: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: $dark-color; /* Replace Variables.darkColor with a CSS variable or hardcoded value */
}

.location h5 {
  font-size: 13px;
  color: #6F6F6F;
  font-weight: 400;
}

.projectText h4 {
  color: $dark-color; /* Replace Variables.darkColor with a CSS variable or hardcoded value */
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 5px;
}

.projectText h5 {
  font-size: 13px;
  color: #6F6F6F;
  font-weight: 400;
  margin-bottom: 6px;
}

.cardIndex {
  font-size: 12px;
  border-radius: 15px;
  color: #EE6A2A;
  text-align: center;
  display: flex;
  font-weight: bold;
}

.cardIndexMain {
  padding-top: 5px;
  color: #000;
  display: flex;
  font-size: 12px;
  font-weight: 300;
  align-items: center;
  justify-content: space-between;
}

.cardIndexMain > div {
  display: flex;
  align-items: center;
}

.cardIndexMain > div > img {
  padding: 0 2px 0 0;
}

.newProjectCard {
  position: relative;
  margin-bottom: 0px;
  background-color: transparent;
}

.ProjectCard {
  box-shadow: none;
  /* margin-bottom: 11px; */
  padding: 10px 15px;
  border-radius: 4px;
  margin-top: -18%;
  z-index: 9;
  position: relative;
  width: 100%;
  background-color: transparent;
}

.ProjectCardBox {
  background-color: rgb(255, 255, 255);
  box-shadow: none;
  /* margin-bottom: 11px; */
  width: 100%;
  border-radius: 7px;
  border: 1px solid #D2DEEB;
}

.titleTag {
  margin-top: -30px;
  margin-bottom: 10px;
}

.iconBgColor {
  width: 31px;
  height: 28px;
  padding: 3px 0 0 0;
  background-color: #17171755;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconFilled {
  color: #FFFFFF;
  margin-top: 0px;
  font-size: 16px;
}

.mobDealWeek {
  display: inline;
  align-items: center;
  width: 100%;
  border-radius: 2px;
  margin-top: 7px;
  margin-bottom: 8px;
}

.mobDealWeekImgOffer {
  width: 35%;
  background: #1A4D72;
  padding: 5px;
  height: 26px;
}

.mobDealWeekImg {
  width: 35%;
  background: #764736;
  padding: 5px;
  height: 26px;
}

.mobDealWeekcontentOffer {
  width: 60%;
  padding: 6px;
  background: linear-gradient(180deg, #6AB4EB, #1A4D72);
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #fff;
  font-size: 10px;
  line-height: 14px;
  height: 26px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.mobDealWeekcontent {
  width: 60%;
  padding: 6px;
  background: linear-gradient(180deg, #FC9B67, #764736);
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #fff;
  font-size: 10px;
  line-height: 14px;
  height: 26px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.flatInfo {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.flatInfo h4 {
  font-size: 15px;
  color: $dark-color; /* Replace Variables.darkColor with a CSS variable or hardcoded value */
  font-weight: 700;
  /* margin-top: -6px; */
  padding-left: 6px;
}

.flatInfo h5 {
  font-size: 13px;
  color: $dark-color; /* Replace Variables.darkColor with a CSS variable or hardcoded value */
  font-weight: 500;
  padding-left: 6px;
  padding-top: 2px;
  margin-top: 0px;
  /* padding-left: 6px; */
}

.rupeeIcon {
  object-position: -29px 12px;
  object-fit: cover;
  width: 14px;
  height: 28px;
  margin-top: -11px;
}

.flatInfo1 {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.flatInfo1 h4 {
  font-size: 15px;
  color: $dark-color; /* Replace Variables.darkColor with a CSS variable or hardcoded value */
  font-weight: 700;
  /* margin-top: -6px; */
  padding-left: 6px;
}

.flatInfo1 h5 {
  font-size: 13px;
  color: var(--dark-color); /* Replace Variables.darkColor with a CSS variable or hardcoded value */
  font-weight: 500;
  padding-left: 6px;
  padding-top: 2px;
  margin-top: -10px;
  margin-left: -2px;
  /* padding-left: 6px; */
}

.flatsubhead h4 {
  font-size: 15px;
  color: var(--dark-color); /* Replace Variables.darkColor with a CSS variable or hardcoded value */
  font-weight: 700;
  margin-top: -6px;
}

.flatsubhead h5 {
  font-size: 13px;
  color: var(--dark-color); /* Replace Variables.darkColor with a CSS variable or hardcoded value */
  font-weight: 500;
}

.phoneIcon {
  background: #0056B2;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  position: absolute;
  right: 30px;
  bottom: 28px;
}

.phoneIcon img {
  object-position: -437px 5px;
  object-fit: cover;
  width: 21px;
  height: 30px;
}

