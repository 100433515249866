@import '/styles/variables.module.scss';
.highlightWord {
  color: $darkorange-color; /* Assuming Variables.darkorangeColor is defined as a CSS variable */
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  position: relative !important;
  left: 0px !important;
  top: 0px !important;
 
}

@media (max-width: 600px) {
  .highlightWord {
    font-size: 16px;
  }
}
@media (min-width:360px) and (max-width:710px) {
.compatibleData {
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-basis: auto;
  width: calc(100% * 6 / var(--Grid-parent-columns) - (var(--Grid-parent-columns) - 6) * (var(--Grid-parent-columnSpacing) / var(--Grid-parent-columns)));
  min-width: 0px;
  box-sizing: border-box;
}
}
