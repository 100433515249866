@import '/styles/variables.module.scss';
// $secondary-color: Variables.secondaryColor; // assuming Variables.secondaryColor is a valid SCSS variable
.highlightWord {
  color: $secondary-color;
  font-weight: 700;
}

.cardTopIcons {
  z-index: 2;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 5px 10px;
}

.cardTop {
  position: relative;
}

.borderimage {
  img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 400px !important;

    @media (max-width: 600px) {
      width: 412px !important;
    }
  }
}

.tagFont {
  border-right: none;

  h3 {
    @media (min-width: 1025px) and (max-width: 1267px) {
      font-size: 22px !important;
    }

    @media (min-width: 769px) and (max-width: 1025px) {
      font-size: 17px !important;
    }
  }


  .MuiTypography-h3 {
    font-family: 'Open Sans', sans-serif !important;
    letter-spacing: -0.01562em !important;

    @media (max-width: 600px) {
      font-size: 18px !important;
      width: 100%;
      border-right: none !important;
    }
  }

  .MuiTypography-h4 {
    @media (max-width: 600px) {
      font-size: 14px !important;
      padding-left: 0px !important;
      margin-top: -11px;
      margin-bottom: 0px !important;
    }
  }
}

.capitals {
  text-transform: uppercase;
}

.developerCardGrid {
  height: 100%;
  max-height: 450px;
}

.cardImg {
  img {
    border-radius: 20;
  }
}

.BlogProject1 {
  // marginBottom: '-2px',
  &:hover {
    background-color: transparent !important;
  }

  .horizontal-menu {
    display: block !important;
  }
}

.txt {
  h1 {
    font-family: 'Open Sans', sans-serif !important;
  }
  h3 {
    font-size: 17px;
  }
}

@media (min-width:360px) and (max-width:768px) {
.txt{
  margin-top: 30px;
}

}


.blogButton {
  margin-top: 8px;

  a {
    width: 90% !important;
    background-color: #0056b2;
    padding: 10px 25px;
    justify-content: center;
    display: flex;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    height: 40px;
  }
}

.knowledgeText {
  text-transform: none;
  // font-family: 'Open Sans', sans-serif !important;
  // font-weight: 400;
  // line-height: 1.8;
  // font-size: 20px;
  // margin-bottom: 15px;
  // padding-left: 18px;
  p {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400;
    line-height: 1.8;
    font-size: 16px;
    margin-bottom: 15px;
    // padding-left: 18px;
    padding-top: 5px;
  }

  @media (max-width: 600px) {
    text-transform: capitalize;
  }
}
