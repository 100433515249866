@import '/styles/variables.module.scss';
.mainTestimonisec {
  background-color: #fafafa;
  margin-top: 30px;
}

@media (max-width: 600px) {
  .mainTestimonisec {
    margin-top: 15px !important;
  }
}
.mobtitleSec {
  padding-top: 15px;
  margin-top: -10px;
}
// .mobtitleSec h3 {
//   font-size: 19px;
// }
.dealWeekTitle h3 {
  color: rgb(0, 86, 178);
  font-size: 17px;
  font-weight: 700;
}

.dealWeekTitle p {
  font-size: 13px;
  color: rgb(23, 23, 23);
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .dealWeekTitle p {
    padding-top: 4px;
  }
}
// .cityName {
//   font-size: 13px;
//   color: rgb(23, 23, 23);
//   font-weight: 500;
// }
.contentslide {
  padding-top: 20px;
  margin-bottom: 30px;
}

.highlightWord {
    color:$darkorange-color;
    font-weight: 700;
}

.capitals {
    text-transform: uppercase;
    /* color: #171717; */
}

.developerCardGrid {
    height: 100%;
    max-height: 450px;
}

.headingText {
    display: flex;
    align-items: center;
}

.headingText h3 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold;
    line-height: 1;
    font-size: 24px;
    color: #cd4b0d;
    padding-right: 18px;
    // border-right: 3px solid #17171721;
    @media (min-width: 769px) and (max-width: 1025px) {
      font-size: 17px !important;
    }
    @media (min-width: 1025px) and (max-width: 1267px) {
      font-size: 22px !important;
    }
}

.headingText p {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400;
    line-height: 1.8;
    font-size: 16px;
    color: #171717 !important;
    // padding-left: 18px;
    padding-top: 5px;
    letter-spacing: 0.0075em;
    @media (min-width: 769px) and (max-width: 899px) {
      font-size: 13px !important;
    }

    @media (min-width: 900px) and (max-width: 1025px) {
      font-size: 14px !important;
    }
}

.highlightWord {
  color: $darkorange-color;
  font-weight: 700;
}

.capitals {
  text-transform: uppercase;
  /* color: #171717; */
}

.developerCardGrid {
  height: 100%;
  max-height: 450px;
}

.headingText {
  display: flex;
  align-items: center;
}

.headingText h3 {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: bold;
  line-height: 1;
  font-size: 24px;
  color: #cd4b0d;
  padding-right: 18px;
  // border-right: 3px solid #17171721;
}

.headingText p {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400;
  line-height: 1.8;
  font-size: 16px;
  color: $darkorange-color;
  // padding-left: 18px;
  letter-spacing: 0.0075em;
  text-transform:'none'
}