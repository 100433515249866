@import '/styles/variables.module.scss';
.root {
  padding: 25px;
  /* Uncomment if needed */
  /* margin-left: 10px; */
  background: #faf9fe !important;
  border-radius: 10px !important; /* Replace with the actual value */
}

.formSubmit {
  width: 100% !important;
  margin-top: 20px !important;
  background-color: #0056b2 !important; /* Replace with the actual value */
  color: #fff !important;
  text-transform: capitalize !important;
  // transition: all 0.3s ease-in-out !important;
  border-radius: 8px !important; /* Replace with the actual value */
  height: 40px !important;
  margin-block-end: 10px !important;
  font: normal normal bold 18px/24px Open Sans, sans-serif !important;
}

.formSubmit:hover {
  box-shadow: none;
  background-color: #0056b2 !important; /* Replace with the actual value */
  color: #ffffff !important;
}

.inputStyle {
  margin-left: 10px;
  padding: 10px;
  font-size: 16px;
}

.specialText {
  cursor: pointer;
  color: #171717; /* Replace with the actual value */
}

.specialText1 {
  color: #171717; /* Replace with the actual value */
}
