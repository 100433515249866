@import '/styles/variables.module.scss';

.highlightWord {
  color: $darkorange-color;
  font-weight: bold;
  font-family: Open Sans, sans-serif !important;
}

.headingText {
  h2 {
    font-family: Open Sans, sans-serif !important;
    font-weight: bold;
    line-height: 1;
    font-size: 24px;
    color: $primary-color;
    padding-right: 18px;
    // border-right: 3px solid #17171721;
    letter-spacing: -0.01562em !important;
    @media (min-width: 769px) and (max-width: 1025px) {
      font-size: 17px;
    }
    @media (min-width: 1025px) and (max-width: 1267px) {
      font-size: 22px;
    }
  }

  p {
    font-family: Open Sans, sans-serif !important;
    font-weight: 400 !important;
    line-height: 1.8;
    font-size: 16px;
    color: $dark-color;
    padding-top:5px;
    text-transform: none;
    @media (min-width: 769px) and (max-width: 899px) {
      font-size: 13px;
    }
    @media (min-width: 900px) and (max-width: 1025px) {
      font-size: 14px;
    }
  }
}



.newprojectname {
  h4 {
    color: #171717;
    font-size: 18px;
    font-weight: 700;
    font-family: Open Sans;
  }
}

.priceContent {
  color: $dark-color;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Open Sans';
}

.noBorderRight h2{
  border-right: 3px solid #17171721;
}